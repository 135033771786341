import React from "react";
import { ConfigProvider, Layout, Menu, theme } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router";
import "./masterlayout.scss";
import AppLogo from "assets/images/appLogo.png";

import Typography from "../Typography";
import color from "theme/color";
import HeaderContent from "app/shared/Header";
import { useEffect } from "react";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { SAVE_USER_INFO } from "app/scenes/auth/redux/auth.action";
import { connect, useDispatch } from "react-redux";
import { apiGet } from "app/services/apiService";
import { useState } from "react";
import Loader from "../Loader";
import UserAvatar from "../UserAvatar";
import { FeatureFlagsProvider } from "app/utils/FeatureFlag/FeatrueFlag";
import { Icon } from "@iconify/react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import headerConfig from "../Header/header.config";
function getItem(label, key, roleKey, icon, children, type) {
  return {
    key,
    icon,
    roleKey,
    children,
    label,
    type,
  };
}
const items = [
  getItem(
    "Dashboard",
    "/",
    "NONE",
    // <AppstoreOutlined style={{ fontSize: "20px" }}

    <Icon
      icon="hugeicons:dashboard-circle"
      style={{ fontSize: "20px", color: "#0E46A3" }}
    />
  ),
  getItem(
    "Properties",
    "sub2",
    "PROPERTY_LISTING_VIEW",
    <Icon
      icon="icon-park:building-one"
      style={{ fontSize: "20px", color: "blue" }}
    />,
    [
      getItem(
        "Add Unit",
        "/property/project/unit/create",
        "PROPERTY_LISTING_VIEW"
      ),
      getItem("Unit List", "/property/list", "PROPERTY_LISTING_VIEW"),
      getItem("Projects", "/property/projects", "PROPERTY_LISTING_MANAGE"),
      getItem("Manage Units", "/property/manage", "PROPERTY_LISTING_MANAGE"),
      getItem(
        "Manage Inventory",
        "/property/inventory",
        "PROPERTY_LISTING_MANAGE"
      ),

      // getItem(
      //   "Create Project",
      //   "/property/project/create",
      //   "PROPERTY_LISTING_MANAGE"
      // ),

      getItem("Investors", "/property/investors", "PROPERTY_LISTING_MANAGE"),
      getItem("Amentities", "/property/amentities", "PROPERTY_LISTING_MANAGE"),
      getItem("Facilities", "/property/facilities", "PROPERTY_LISTING_MANAGE"),
      getItem("Categories", "/property/types", "PROPERTY_LISTING_MANAGE"),
      getItem("Developers", "/property/developers", "PROPERTY_LISTING_MANAGE"),
      getItem("Community", "/property/community", "PROPERTY_LISTING_MANAGE"),

      getItem("Banks", "/property/banks", "PROPERTY_LISTING_MANAGE"),
      getItem("Sale History", "/property/sales", "PROPERTY_LISTING_MANAGE"),

      getItem(
        "Payment Plans",
        "/property/payment_plan",
        "PROPERTY_LISTING_MANAGE"
      ),
    ]
  ),
  getItem(
    "Broker",
    "brokers/list",
    "BROKER_VIEW",
    <Icon
      icon="icon-park:every-user"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Promoter",
    "promoters/list",
    "PROMOTER_VIEW",
    <Icon icon="icon-park:user" style={{ fontSize: "20px", color: "blue" }} />
  ),
  getItem(
    "Employee",
    "employee/list",
    "EMPLOYEE_VIEW",
    <Icon
      icon="icon-park:user-business"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Customers",
    "customer/list",
    "EMPLOYEE_VIEW",
    <Icon icon="icon-park:user" style={{ fontSize: "20px", color: "blue" }} />
  ),

  getItem(
    "Leads",
    "leads/list",
    "LEAD_VIEW",
    <Icon
      icon="fluent-color:document-folder-20"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Reports",
    "reports/list",
    "NONE",
    <Icon
      icon="icon-park:table-report"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Leaves",
    "/leaves/dashboard",
    "LEAVE_VIEW",
    <Icon
      icon="flat-color-icons:leave"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Announcement",
    "/announcement/list",
    "LEAVE_VIEW",
    <Icon
      icon="icon-park:announcement"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),

  getItem(
    "Offers",
    "/offers/list",
    "LEAVE_VIEW",
    <Icon
      icon="icon-park:weixin-cards-offers"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
  getItem(
    "Settings",
    "settings",
    "NONE",
    <Icon
      icon="flat-color-icons:settings"
      style={{ fontSize: "20px", color: "blue" }}
    />
  ),
];

const { Header, Content, Footer, Sider } = Layout;
const MasterLayout = (props) => {
  const [isLoading, setLoading] = useState(true);
  console.log("userAccess:", props.userAccess);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDependencies();
  }, []);

  // console.log("item is:", items);
  const onboardingFlow = {
    3002: "/onboard/profile",
    3003: "/onboard/profile_document",
    3004: "/onboard/profile_bank",
  };
  const fetchDependencies = async () => {
    const response = await apiGet(API_ENDPOINT_DASHBOARD_HUDDLE);
    if (response.status) {
      dispatch({ type: SAVE_USER_INFO, payload: response.data.userInfo });

      if (response.data.onboarding) {
        navigate(onboardingFlow[response.data.onboarding]);
      } else {
        console.log(response);

        setLoading(false);
      }
    } else {
      localStorage.clear();
      navigate("/auth/authenticate");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/authenticate");
  };
  const handleMenu = (menu) => {
    navigate(menu.key);
  };

  const filterOutMenu = (menus) => {
    let finalMenus = [];
    menus.forEach((menu) => {
      if (
        (props.userAccess &&
          menu.roleKey !== "NONE" &&
          props.userAccess.includes(menu.roleKey)) ||
        menu.roleKey === "NONE"
      ) {
        let pushable = menu;
        if (pushable.children) {
          pushable.children = pushable.children.filter((child) =>
            props.userAccess.includes(child.roleKey)
          );
        }
        finalMenus.push(pushable);
      }
    });
    return finalMenus;
  };

  const location = useLocation();

  const headerIndex = Object.keys(headerConfig).findIndex((header) =>
    location.pathname.includes(header)
  );
  // console.log("headerINdex", headerIndex);

  return (
    <FeatureFlagsProvider feature_flag_values={props.userAccess}>
      <Header
        style={{
          padding: 0,
          background: "white",
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          transition: "background 0.3s ease",
          position: "fixed", // Make the header fixed
          top: 0, // Align it to the top
          left: 0, // Align it to the left
          right: 0, // Stretch it to the right
          zIndex: 1000, // Ensure it stays above other content
        }}
      >
        <HeaderContent {...props} />
      </Header>

      <Layout
        style={{
          height: "100vh",
          backgroundColor: "#D2E0FB",
          marginTop: "3rem",
        }}
        className="masterLayout"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Sider
              breakpoint="md"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              style={{
                position: "absolute",
                height: "100vh",
                zIndex: 1,
                background: `linear-gradient(
                  90deg, #FFEFEF 0%, #EEF5FF 100%
                                  )`,
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
              width={256}
              theme="light"
            >
              <div className="leftSideNav">
                <div className="LogoMenuContainer">
                  {/* <div className="appLogoContainer">
                    <img
                      src={AppLogo}
                      alt="appLogo"
                      className="profileImage"
                      style={{ height: "1.5rem" }}
                    />
                  </div>
                  <div className="userInfoContainer">
                    <img
                    src={
                      (props.userInfo && props.userInfo.user_profile.avatar) ||
                      DummyAvatar
                    }
                    alt="userProfileImage"
                    className="profileImage"
                  />
                    <div className="profileImage">
                      <UserAvatar
                        avatar={
                          props.userInfo && props.userInfo.user_profile.avatar
                        }
                      />
                    </div>
                    <div className="userInfo">
                      <Typography variant="body1" style={{ fontSize: 16 }}>
                        {props.userInfo &&
                          `${props.userInfo.user_profile.first_name.toUpperCase()} ${""}`}
                      </Typography>

                      <Typography variant="caption" color="gray_text">
                        {props.userInfo && props.userInfo.email}
                      </Typography>
                    </div>
                  </div> */}
                  <div className="menuContainer scrollbar" id="style-4">
                    <ConfigProvider
                      theme={{
                        components: {
                          Menu: {
                            itemBorderRadius: 0,
                            inlineIndent: 0,
                            groupTitleColor: color.red,
                            colorBgTextActive: color.red,
                          },
                        },
                      }}
                    >
                      <Menu
                        onClick={handleMenu}
                        defaultSelectedKeys={["1"]}
                        defaultOpenKeys={["sub1"]}
                        mode="inline"
                        theme="light"
                        items={filterOutMenu(items)}
                        style={{
                          color: color.gray_dark,
                          width: "100%",
                          margin: 0,
                          paddingTop: "2rem",
                          background: `linear-gradient(
                            90deg,#FFEFEF  0%, #EEF5FF 100%
                                            )`,
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </div>
                <div className="logout" onClick={handleLogout}>
                  <Icon
                    icon="marketeq:sign-out-left"
                    style={{ fontSize: "20px", color: "blue" }}
                  />

                  <Typography
                    variant="button"
                    style={{ marginLeft: 20, color: "#8792A4" }}
                  >
                    Logout
                  </Typography>
                </div>
              </div>
            </Sider>
            <Layout
              className="MasterLayoutContent scrollbar"
              id="style-4"
              style={{
                height: "100vh",
                overflowY: "auto",
                background: `linear-gradient(
                  110deg, #EEF5FF 0%, #FFEFEF 100%
                                  )`,
              }}
            >
              <div
                role="presentation"
                style={{ paddingTop: "16px", paddingLeft: "16px" }}
              >
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" to="/dashboard">
                    Dashboard
                  </Link>
                  <Typography sx={{ color: "text.primary" }}>
                    {Object.values(headerConfig)[headerIndex] ||
                      "Broker Dashboard"}
                  </Typography>
                </Breadcrumbs>
              </div>

              <Content
                style={{
                  background: `linear-gradient(
                    110deg, #EEF5FF 0%, #FFEFEF 100%
                                    )`,
                  padding: 24,
                }}
              >
                <div
                  style={{
                    minHeight: 360,
                    background: `linear-gradient(
                      110deg, #EEF5FF 0%, #FFEFEF 100%
                                      )`,
                    height: "100%",
                  }}
                >
                  <Outlet />
                </div>
              </Content>
            </Layout>
          </>
        )}
      </Layout>
    </FeatureFlagsProvider>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  userAccess: state.auth.userAccess,
});

export default connect(mapStateToProps, {})(MasterLayout);
