import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Navigate } from "react-router";

const ProtectedRoute = (props) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("token");
  const hasAccess =
    props.accessKey && (props.userAccess || []).includes(props.accessKey);
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/auth/authenticate" />;
  }
  if (!hasAccess && props.accessKey) {
    dispatch({
      type: ADD_TOAST_MESSAGE,
      payload: {
        message:
          "You don't have access to this resource, redirecting back to dashboard",
        type: 0,
      },
    });
    return <Navigate to="/" />;
  }
  return props.children;
};

const mapStateToProps = (state) => ({
  userAccess: state.auth.userAccess,
});

export default connect(mapStateToProps, {})(ProtectedRoute);
